import React, { useEffect, useState } from 'react';

import './Chat.css';

import { useHistory } from "react-router-dom";
import {
  SendBirdProvider,
  OpenChannel,
  ChannelSettings,
} from 'sendbird-uikit';
import 'sendbird-uikit/dist/index.css';

export default function Chat({ userId, nickname, theme }) {
  const history = useHistory();
  useEffect(() => {
    if (!userId || !nickname) {
      history.push('/');
    }
  }, [userId, nickname, history]);
  const [showSettings, setShowSettings] = useState(false);
  const [currentChannelUrl] = useState(null);
  return (
    <div style={{ height: '80vh', width: '60vh', margin: '0px auto' }}>
      <SendBirdProvider
        appId={process.env.APP_ID}
        theme={theme}
        userId={userId}
        nickname={nickname}
      >
        <div style={{ height: '100vh' }}>
          <OpenChannel
            channelUrl="sendbird_open_channel_15999_fb530203c69397d7e7b2dbc91b36fe83bf48dec4"
          />
        </div>
        {showSettings && (
          <div className="sendbird-app__settingspanel-wrap">
            <ChannelSettings
              channelUrl={currentChannelUrl}
              onCloseClick={() => { setShowSettings(false); }}
            />
          </div>
        )}
      </SendBirdProvider>
    </div>
  )
}
